import React from "react"
import { graphql, Link } from "gatsby"
import _ from 'lodash'

import Layout from '../components/layout'

import PostCol2 from "../components/UI/PostCol2"
import Introduction from "../components/UI/Introduction"
import SubscribeFull from "../components/UI/SubscribeFull"

function BlogCategory(props) {
    const {
        data: {
            wpgraphql: { categories, page, category, footer, header, serviceCategories },
        },
    } = props;

    console.log(category)

    // categories.edges = categories.edges;

    function constructMetaData(category, currentPage) {
        let payload = {
            title: category.seo.title + (currentPage !== 1 ? ` | Page ${currentPage}` : ""),
            description: category.seo.metaDesc,
            canonical: `https://blog.enimeris.com/${category.slug}/`,
            fbTitle: category.seo.opengraphTitle ? category.seo.opengraphTitle : category.seo.title,
            fbDescription: category.seo.opengraphDescription ? category.seo.opengraphDescription : category.seo.metaDesc,
            fbUrl: `https://blog.enimeris.com/${category.slug}`,
            fbType: "website",
            locale: "el",
            image: category.seo.opengraphImage ? category.seo.opengraphImage.sourceUrl : ""
        }
        return payload
    }

    // function getCanonicalUrl(category) {
    //   console.log( category )
    // 	return `https://blog.enimeris.com/${category.slug}/`;
    // }

    return (
        <Layout metaData={constructMetaData(category, props.pageContext.currentPage)} header={header} footer={footer} services={serviceCategories} >

            {category.categoryPageH1.categoryImage && (
                <Introduction backImg={category.categoryPageH1.categoryImage} title={category.categoryPageH1.categoryH1} />
            )}

            <div className="container px-4 pb-12 md:px-0 max-w-6xl mx-auto">
                <div className="mx-0 mt-12 flex flex-wrap w-full">
                    <div className="flex flex-wrap justify-center lg:justify-between w-full lg:w-2/3">
                        {category.posts.edges.map((post, i) => (
                            <PostCol2 post={post.node} key={`post-${i}`} />
                        ))}
                    </div>
                    <div className="w-full lg:w-1/3 pt-6">
                        <div>
                            <div className="cat-name">Κατηγοριες</div>
                            <div>
                                <ul className="cat-ul">
                                    {categories.edges.map((node, i) => {
                                        if ((node.node.name !== "Uncategorized" && node.node.name !== "Μη κατηγοριοποιημένο") && (node.node.posts.edges.length > 0)) {

                                            return (
                                                <li key={"cat" + i} className="cat-li">
                                                    <Link to={`/${node.node.slug}/`} className="flex flex-wrap no-underline hover:no-underline">
                                                        {node.node.name}
                                                    </Link>
                                                </li>
                                            )
                                        }
                                    })}
                                </ul>
                            </div>
                            <SubscribeFull extraText={false} classes="p-4 mt-16" />

                        </div>
                    </div>
                </div>

                <ul className="flex justify-center py-8">
                    {props.pageContext.numberOfPages > 1 && (Array.from({ length: props.pageContext.numberOfPages }).map((page, index) => (
                        <li key={index} className="mr-4"  >
                            <Link
                                to={index === 0 ? `/${category.slug}` : `/${category.slug}/page/${index + 1}/`}
                                className={props.pageContext.currentPage === index + 1 ? "active-pagination-link py-2 px-4 pagination-link" : "py-2 px-4 pagination-link"}
                            >
                                {index + 1}
                            </Link>
                        </li>
                    )))}
                </ul>

                <SubscribeFull extraText={true} />

            </div>
        </Layout>
    )
}

export default BlogCategory

export const pageQuery = graphql`
  query GET_CATEGORY($id: ID!, $startPoint: String!, $postsPerPage: Int!) {
    wpgraphql {
        footer: menu(id:"bmF2X21lbnU6MjQ="){
            menuId
            id
            slug
            name
            menuItems {
                edges {
                    node {
                        label
                        url
                        childItems {
                            edges {
                                node {
                                    label
                                    url
                                }
                            }
                        }
                    }
                }
            }
        }

        header: menu(id:"bmF2X21lbnU6MjI="){
            menuId
            id
            slug
            name
            menuItems {
                edges {
                    node {
                        label
                        url
                        childItems {
                            edges {
                                node {
                                    label
                                    url
                                }
                            }
                        }
                    }
                }
            }
        }
        serviceCategories(first: 100, where: {language: EL}) {
            edges {
                node {
                    name
                    slug
                    language {
                        code
                    }
                    services {
                        edges {
                            node {
                                uri
                                title
                            }
                        }
                    }
                }
            }
        }
      categories {
        edges {
            node {
            name
            description
            slug
            posts {
                edges {
                    node {
                    id
                    }
                }
            }
            }
        }
      }
      page(id:"cG9zdDoxODc5") {
        title
      }
      category(id: $id) {
        id
        name
        slug
        seo {
          title
          metaDesc
          opengraphDescription
          opengraphTitle
          opengraphImage {
            sourceUrl
          }
        }
        categoryPageH1{
            categoryH1
            categoryImage{
                sourceUrl
						altText
						imageFile {
						  childImageSharp {
							fluid(maxWidth: 2080, quality:60){
								base64
								aspectRatio
								src
								srcSet
								srcWebp
								srcSetWebp
								sizes
							}
						  }
						}
            }
        }
        posts(after: $startPoint, first: $postsPerPage) {
          edges {
            node {
              title
              excerpt
              slug
              date
              modified
              author{
                firstName
                lastName
                avatar{
                  url
                }
              }
              postRT{
                postReadTime
              }
              featuredImage {
                sourceUrl
                altText
                imageFile {
                  childImageSharp {
                  fluid{
                    base64
                    aspectRatio
                    src
                    srcSet
                    srcWebp
                    srcSetWebp
                    sizes
                  }
                  }
                }
              }
              categories{
                nodes{
                  name
                  slug
                }
              }
            }
          }
        }
      }
    }
  }
`
